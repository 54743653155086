.btn {
  color: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.default {
  background-color: #1a56db;
  transition: background-color 0.4s;
}

.default:hover {
  background-color: #1e429f;
}

.white {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  color: #1f2a37;
  transition: background-color 0.4s;
}

.white:hover {
  background-color: #f3f4f6;
}

.small {
  padding: 2px 10px;
  font-size: 12px;
  line-height: 18px;
}

.middle {
  padding: 5.5px 10px;
  font-size: 14px;
  line-height: 21px;
}

.large {
  padding: 8px 30px;
  font-size: 16px;
  line-height: 24px;
}

.disable {
  background: #A4CAFE;
  cursor: default;
}
