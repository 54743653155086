.header {
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.ctrl {
  display: flex;
  align-items: center;
  gap: 32px;
}

.logo {
  display: block;
  width: 116px;
  height: 36px;
  margin-right: 32px;
  cursor: pointer;
  background: url(../../assets/logo.svg) center center no-repeat;
}

.view {
  display: flex;
  align-items: center;
  gap: 16px;
}
