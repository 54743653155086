.container {
  background-color: #ffffff;
  background-image: url(../../assets/footer-bg.svg);
  background-position: bottom right;
  padding: 0 120px;
}

@media only screen and (max-width: 900px) {
  .container {
    padding: 0 20px;
  }
}

.top {
  display: flex;
  align-items: center;
  position: relative;
}

.brand {
  flex: 1;
  margin-top: 64px;
}

.logo {
  width: 140px;
  height: 48px;
  background-image: url(../../assets/footer-logo.svg);
  background-repeat: no-repeat;
  margin-bottom: 12px;
}

.text {
  font-size: 14px;
  line-height: 21px;
  color: #6B7280;
  width: 273px;
}

.nav {
  display: flex;
  gap: 120px;
  margin-top: 80px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #374151;
  cursor: pointer;
}

.item:hover {
  color: #1C64F2;
}

.ok {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #31C48D;
  margin-left: 7px;
}

.bottom {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
  gap: 8px;
  border-top: 1px solid #F3F4F6;
  margin-top: 88px;
}

.copywrite {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6B7280;
}

.language {
  color: #374151;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  gap: 8px;
}

.dropdown{
  border-radius: 4px;
  color: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 14px;
  cursor: pointer;
}

.shade {
  position: fixed;
  inset: 0;
}

.menu{
  position: absolute;
  bottom: 35px;
  min-width: 101px;
  text-align: center;
  background-color: #ffffff;
  border: 0.5px solid #E5E7EB;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 4px;
}

.button{
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px 8px;
  line-height: 21px;
}

.button:hover {
  background-color: #F3F4F6;
}

.active {
  background-color: #F3F4F6;
}

.title {
  margin: 0 2px 0 6px;
}

.menu > div{
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
}

.menu > div:hover{
  background: #F3F4F6;
}
