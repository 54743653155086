.box {
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 320px;
  height: 366px;
  position: relative;
}

.title {
  color: #111928;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 80px;
}

.header {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #111928;
  text-align: left;
  margin-bottom: 8px;
}

.content {
  font-size: 16px;
  line-height: 24px;
  color: #6B7280;
  text-align: left;
}

.bg {
  width: 318px;
  height: 220px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 8px 8px;
}

.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
}

.btn {
  position: absolute;
  left: 24px;
  bottom: 24px;
  color: #1C64F2;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: end;
  gap: 6px;
  cursor: pointer;
}

.arrow {
  width: 16px;
  height: 16px;
}

.block4-1 {
  background-image: url(../../assets/block4-1.svg);
}

.block4-2 {
  background-image: url(../../assets/block4-2.svg);
}

.block4-3 {
  background-image: url(../../assets/block4-3.svg);
  background-repeat: repeat-x;
}


@media only screen and (max-width: 1500px) {
  .card {
    width: 270px;
    height: 366px;
    padding: 12px;
  }

  .bg {
    width: 268px;
  }
}