.box {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
@media only screen and (max-width: 750px) {
  .box {
    width: 750px;
  }
}

/* 小设备 (平板电脑和大型手机，750 像素及以上) */
@media only screen and (min-width: 750) {
  .box {
    width: 750px;
  }
}

/* 大型设备（笔记本电脑/台式机，992 像素及以上）*/
@media only screen and (max-width: 1510px) {
  .box {
    width: 850px;
  }
} 

/* 大型设备（笔记本电脑/台式机，992 像素及以上）*/
@media only screen and (min-width: 1510px) {
  .box {
    width: 100%;
  }
} 


.card {
  width: 260px;
  height: 340px;
  position: relative;
  cursor: pointer;
}

.title {
  color: #111928;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 80px;
}

.icon {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D5F5F6;
  box-shadow: 0px 2.33333px 3.5px -0.583333px rgba(0, 0, 0, 0.1), 0px 1.16667px 2.33333px -1.16667px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  margin-bottom: 16px;
}

.iconcontent {
  width: 30px;
  height: 30px;
}

.header {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #111928;
  text-align: left;
  margin-bottom: 8px;
}

.content {
  font-size: 16px;
  line-height: 24px;
  color: #6B7280;
  text-align: left;
}

.btn {
  display: flex;
  gap: 8px;
  position: absolute;
  bottom: 24px;
  left: 24px;
  opacity: 0;
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  border-radius: 12px;
  opacity: 0;
}

.card:hover .btn{
  opacity: 1;
}

.card:hover .bg{
  opacity: 1;
}

.arrow {
  height: 13px;
  width: 13px;
  margin-left: 8px;
}