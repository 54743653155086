.content {
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 96px;
  font-family: 'Inter';
}

.page-title {
  flex: 0 0 300px;
}

.title {
  margin: 96px 0 24px 0;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
}
.timestamp {
  color: #1c64f2;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.section {
  margin-bottom: 48px;
  width: 760px;
  text-align: start;
}

.h2 {
  margin: 0 0 24px 0;
  color: #111928;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
}

.h3 {
  margin: 0 0 16px 0;
  color: #111928;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.text {
  margin: 0;
  color: #4b5563;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.bold {
  font-weight: 600;
}

.link {
  padding: 0;
  color: #4b5563;
  text-decoration: underline;
  cursor: pointer;
}
.link:hover {
  color: #1c64f2;
  background-color: transparent;
}

.text-margin {
  margin-bottom: 16px;
}
  