.container {
  background-size: cover;
  background-image: url(../../assets/bg.svg);
  background-repeat: no-repeat;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alph {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(12px);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: -1px -1px 0px #ffffff, 1px 1px 3px rgba(102, 111, 128, 0.08);
}

.r {
  margin-right: 4px;
}

.l {
  margin-left: 4px;
}

.text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #4b5563;
}

.link {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #1c64f2;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.title {
  font-weight: 800;
  font-size: 60px;
  line-height: 154.02%;
  color: #111928;
}

.tip {
  font-size: 20px;
  text-align: center;
  color: #6b7280;
}

.btn {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 24px 0 80px 0;
}

.btnicon {
  margin-right: 8px;
}

.coming {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  flex: 1;
  padding: 2px 8px;
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #1f2a37;
}

.imgcontainer {
  box-sizing: border-box;
  max-width: 1280px;
  min-width: 720px;
  position: relative;
  padding: 0 100px;
}

.img {
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  height: 437.5px;
  width: 700px;
}

.imgsmall {
  position: absolute;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 416px;
  width: 410px;
  right: 0;
  bottom: -60px;
}

.zh {
  background-image: url(../../assets/block-1-sub-img-zh.png);
}

.en {
  background-image: url(../../assets/block-1-sub-img-en.png);
}

@media only screen and (max-width: 750px) {
  .imgcontainer {
    padding: 0 20px;
  }
}

/* 小设备 (平板电脑和大型手机，750 像素及以上) */
@media only screen and (min-width: 750) {
  .imgcontainer {
    padding: 0 20px;
  }
}

/* 中型设备（平板电脑，768 像素及以上）*/
@media only screen and (min-width: 768px) {
  .imgcontainer {
    padding: 0 60px;
  }
}

/* 大型设备（笔记本电脑/台式机，992 像素及以上）*/
@media only screen and (min-width: 992px) {
  .imgcontainer {
    padding: 0 80px;
  }

  .img {
    height: 520px;
    width: 832px;
  }

  .imgsmall {
    height: 436px;
    width: 443px;
  }
}

/* 超大型设备（大型笔记本电脑和台式机，1200 像素及以上）*/
@media only screen and (min-width: 1200px) {
  .imgcontainer {
    padding: 0 120px;
  }

  .img {
    height: 600px;
    width: 960px;
  }

  .imgsmall {
    height: 523px;
    width: 530px;
  }
}
