.container {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-left: 310px;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: url(../../assets/block-3-bg.svg) no-repeat;
  padding: 0 48px 124px 100px;
  box-sizing: content-box;
}

.detail {
  text-align: left;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  width: 472px;
  min-height: 192px;
  background: linear-gradient(272.68deg, rgba(246, 248, 251, 0.8) 0%, #F0F4FA 100%);
  box-shadow: -1px -1px 0px #FFFFFF, 1px 1px 3px rgba(102, 111, 128, 0.08);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.card:hover {
  box-shadow: -1px -1px 0px #FFFFFF, 1px 1px 3px rgba(102, 111, 128, 0.08), 8px 8px 12px rgba(102, 111, 128, 0.1), -8px -8px 12px rgba(255, 255, 255, 0.6);
}

.icon {
  width: 40px;
  height: 40px;
  background: #E1EFFE;
  border-radius: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #111928;
  text-align: left;
  margin-bottom: 8px;
}

.btn {
  background: #C3DDFD;
  border-radius: 6px;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #1C64F2;
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
}

.content {
  font-size: 16px;
  line-height: 24px;
  color: #6B7280;
  text-align: left;
}

.img {
  width: 1280px;
  height: 840px;
  border-radius: 12px;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05);
  object-fit: cover;
  object-position: left;
}
