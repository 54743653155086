.container {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.horn {
  background-image: url(../../../assets/icons/horn.svg);
}

.arrow-blue-left {
  background-image: url(../../../assets/icons/arrow-blue-left.svg);
}

.github {
  background-image: url(../../../assets//icons/github.svg);
}

.github {
  background-image: url(../../../assets//icons/github.svg);
}

.old-man {
  background-image: url(../../../assets//icons/old-man.svg);
}

.writing {
  background-image: url(../../../assets//icons/writing.svg);
}

.computer {
  background-image: url(../../../assets//icons/computer.svg);
}

.robot {
  background-image: url(../../../assets//icons/robot.svg);
}

.magic {
  background-image: url(../../../assets//icons/magic.svg);
}

.arrow-white-left {
  background-image: url(../../../assets//icons/arrow-white-left.svg);
}

.vector {
  background-image: url(../../../assets//icons/vector.svg);
}

.terminal {
  background-image: url(../../../assets//icons/terminal.svg);
}

.paper-airplane {
  background-image: url(../../../assets//icons/paper-airplane.svg);
}

.clipboard-list {
  background-image: url(../../../assets//icons/clipboard-list.svg);
}

.dot {
  background-image: url(../../../assets//icons/dot.svg);
}

.arrow-blue-w-left {
  background-image: url(../../../assets/icons/arrow-blue-w-left.svg);
}

.discord {
  background-image: url(../../../assets/icons/discord.svg);
}

.language {
  background-image: url(../../../assets/icons/language.svg);
}

.tranggle-down {
  background-image: url(../../../assets/icons/tranggle-down.svg);
}
